/* eslint-disable camelcase */
import React from 'react';
import { FiPower } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useAuth } from 'hooks/auth';
import logoImg from 'assets/logo.svg';
import { Container, HeaderContent, Profile } from './styles';

const Header: React.FC<Record<string, unknown>> = (): JSX.Element => {
  const { user, signOut } = useAuth();
  return (
    <Container>
      <HeaderContent>
        <Link to="/dashboard">
          <img src={logoImg} alt="warehouse_explorer" />
        </Link>
        <nav>
          <Profile>
            <img
              src={user.avatar_url}
              alt={user.name}
              onError={event => {
                event.currentTarget.src = `${process.env.REACT_APP_IMG_URL}/image_not_available.png`;
              }}
            />
            <div>
              <span>Bem-vindo,</span>
              <Link to="/profile">
                <strong>{user.name}</strong>
              </Link>
            </div>
          </Profile>
          <button type="button" onClick={signOut}>
            <FiPower />
          </button>
        </nav>
      </HeaderContent>
    </Container>
  );
};

export default Header;
