import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface FormProps {
  hasError: boolean;
}

export const Header = styled.header`
  /* display: flex; */
  padding: 32px 0;
  /* justify-content: right; */
  max-width: 920px;
  /* align-content: space-between;
  justify-content: space-between; */
`;

export const HeaderContent = styled.div`
  /* max-width: 1120px; */
  margin: 0 auto;
  display: flex;

  align-items: center;
  /* float: left; */
  /* none !important; */

  > img {
    height: 40px;
  }

  button {
    margin-left: auto;
    background: transparent;
    border: 0;

    svg {
      color: #999591;
      width: 20px;
      height: 20px;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 320px;

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;
  }

  span {
    color: #3a3a3a;
  }

  a {
    text-decoration: none;
    color: #1b78b3;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const Title = styled.h1`
  font-size: 48px;
  color: #3a3a3a;
  max-width: 450px;
  line-height: 56px;

  margin-top: 80px;
`;

export const Form = styled.form<FormProps>`
  margin-top: 40px;
  max-width: 920px;

  display: flex;

  input {
    flex: 1;
    height: 70px;
    padding: 0 24px;
    border: 0;
    border-radius: 5px 0 0 5px;
    color: #3a3a3a;
    border: 2px solid #fff;
    border-right: 0;

    ${props =>
      props.hasError &&
      css`
        border-color: #c53030;
      `}

    &::placeholder {
      color: #a8a8b3;
    }
  }

  button {
    width: 210px;
    height: 70px;
    background: #ff0000;
    border: 0;
    border-radius: 0 5px 5px 0;
    color: #fff;
    font-weight: bold;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, '#ff0000')};
    }
  }
`;

export const Errors = styled.span`
  display: block;
  color: #c53030;
  margin-top: 8px;
`;

export const WarehouseItemCards = styled.div`
  margin-top: 80px;
  max-width: 872px;

  a {
    box-sizing: content-box;
    background: #fff;
    border-radius: 5px;
    width: 100%;
    padding: 24px;
    display: block;
    text-decoration: none;

    display: flex;
    align-items: center;
    /* justify-content: stretch; */
    transition: transform 0.2s;

    &:hover {
      transform: translateX(10px);
    }

    & + a {
      margin-top: 16px;
    }

    img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
    }

    div {
      display: flex;
      flex-direction: column;
      margin: 0 0 0 16px;
      flex: 1;

      min-width: 100px;
      overflow: hidden;

      strong {
        font-size: 20px;
        color: #3d3d4d;
      }

      p {
        /* display: inline-block; */
        box-sizing: content-box;
        font-size: 18px;
        color: #a8a8b3;
        margin-top: 4px;
      }

      /* div {
        align-items: center;
        margin: 0 16px;
        flex: 1;

        strong {
          font-size: 30px;
          color: #3d3d4d;
        }

        p {
          font-size: 18px;
          color: #a8a8b3;
          margin-top: 4px;
        }
      } */
    }

    div.quantity {
      display: flex;
      flex-direction: column;
      justify-content: right;
      /* align-content: space-between; */
      /* display: inline-block; */
      /* box-sizing: border-box; */
      /* width: 100px; */
      margin: 0 40px 0 0;
      /* min-height: 100px; */
      overflow: hidden;
      /* align-items: center; */
      /* justify-content: center; */
      text-align: right;
      /* flex-shrink: 1; */
      /* flex: 1; */
      strong {
        /* display: inline-block; */
        text-align: right;
        font-size: 32px;
        color: #3d3d4d;
      }

      p {
        /* display: inline-block; */
        /* box-sizing: content-box; */
        font-size: 18px;
        color: #a8a8b3;
        margin-top: 4px;
      }
    }

    svg {
      margin-left: auto;
      color: #cbcbd6;
    }
  }
`;
