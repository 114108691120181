import styled from 'styled-components';

export const Container = styled.div`
  /* padding: 30px 0; */
`;

export const HeaderContent = styled.div`
  max-width: 920px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > img {
    height: 40px;
  }

  nav {
    display: flex;
  }

  button {
    background: transparent;
    border: 0;
    margin-left: 32px;

    svg {
      color: #999591;
      width: 20px;
      height: 20px;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  /* margin-left: auto; */

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;
  }

  span {
    color: #3a3a3a;
  }

  a {
    text-decoration: none;
    color: #1b78b3;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const WarehouseItemCards = styled.div`
  margin-top: 80px;
  max-width: 872px;

  a {
    box-sizing: content-box;
    background: #fff;
    border-radius: 5px;
    width: 100%;
    padding: 24px;
    display: block;
    text-decoration: none;

    display: flex;
    align-items: center;
    /* justify-content: stretch; */
    transition: transform 0.2s;

    &:hover {
      transform: translateX(2px);
      cursor: unset;
    }

    & + a {
      margin-top: 16px;
    }

    img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
    }

    div {
      display: flex;
      flex-direction: column;
      margin: 0 0 0 16px;
      flex: 1;

      min-width: 100px;
      overflow: hidden;

      strong {
        font-size: 20px;
        color: #3d3d4d;
      }

      p {
        /* display: inline-block; */
        box-sizing: content-box;
        font-size: 18px;
        color: #a8a8b3;
        margin-top: 4px;
      }

      /* div {
        align-items: center;
        margin: 0 16px;
        flex: 1;

        strong {
          font-size: 30px;
          color: #3d3d4d;
        }

        p {
          font-size: 18px;
          color: #a8a8b3;
          margin-top: 4px;
        }
      } */
    }

    div.quantity {
      display: flex;
      flex-direction: column;
      justify-content: right;
      /* align-content: space-between; */
      /* display: inline-block; */
      /* box-sizing: border-box; */
      /* width: 100px; */
      margin: 0 40px 0 0;
      /* min-height: 100px; */
      overflow: hidden;
      /* align-items: center; */
      /* justify-content: center; */
      text-align: right;
      /* flex-shrink: 1; */
      /* flex: 1; */
      strong {
        /* display: inline-block; */
        text-align: right;
        font-size: 32px;
        color: #3d3d4d;
      }

      p {
        /* display: inline-block; */
        /* box-sizing: content-box; */
        font-size: 18px;
        color: #a8a8b3;
        margin-top: 4px;
      }
    }

    svg {
      margin-left: auto;
      color: #cbcbd6;
    }
  }
`;
