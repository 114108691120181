/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useToast } from 'hooks/toast';
import { GoogleSpreadsheet, GoogleSpreadsheetRow } from 'google-spreadsheet';
import Pedido from 'dtos/IWarehouseRepositionDTO';
import { useAuth } from 'hooks/auth';
import creds from './credentials.json';
import {
  ReposissionContainer,
  FormContainer,
  ReposissionInput,
  ReposissionButton,
} from './styles';

interface ItemProps {
  partNumber: string;
  itemCode: string;
  description: string;
}
interface FormReposicaoProps {
  item: ItemProps;
}

export const FormReposicao: React.FC<FormReposicaoProps> = ({ item }) => {
  const [pedido, setPedido] = useState<Pedido>();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { handleSubmit, register, reset } = useForm<Pedido>();

  async function addPedido(data: Pedido): Promise<GoogleSpreadsheetRow> {
    const doc = new GoogleSpreadsheet(creds.ID_REPOSITION);
    await doc.useServiceAccountAuth({
      client_email: creds.client_email,
      private_key: creds.private_key,
    });

    await doc.loadInfo();

    const sheet = doc.sheetsByIndex[0];
    const addRows = await sheet.addRow({ ...data });
    return addRows;
  }
  function verificationPedido(data: Pedido): void {
    if (data.Replacement) {
      addToast({
        type: 'success',
        title: 'Enviado com Sucesso!',
        description: 'Dados enviados para a planilha, até a proxima 🚀.',
      });
      addPedido(data);
    } else {
      addToast({
        type: 'error',
        title: 'Por favor, verifique a quantidade',
        description: 'Valor digitado não é valido valido.',
      });
    }
  }
  async function handleCreateNewPedido(data: Pedido): Promise<Pedido> {
    const newCreatePedido: Pedido = {
      ID: Date.now(),
      'Part Number': item?.partNumber,
      'Item Code': item?.itemCode,
      Description: item?.description,
      Replacement: data?.Replacement,
      Name: user.name,
      Date: new Date().toLocaleString('pt-BR').substring(0, 10),
      Hour: new Date().toLocaleString('pt-BR').substring(11, 19),
    };
    setPedido(newCreatePedido);
    verificationPedido(newCreatePedido);
    reset();
    return newCreatePedido;
  }

  return (
    <ReposissionContainer>
      <FormContainer onSubmit={handleSubmit(handleCreateNewPedido)}>
        {/* <label htmlFor="reposition" /> */}
        <ReposissionInput
          type="number"
          placeholder="Qtd"
          min={1}
          {...register('Replacement', { valueAsNumber: true })}
        />
        <ReposissionButton type="submit">Repor</ReposissionButton>
      </FormContainer>
    </ReposissionContainer>
  );
};
