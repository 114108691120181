import axios from 'axios';

const token = localStorage.getItem('@warehouse_explorer:refresh_token');

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axios.defaults.headers.Authorization = `Bearer ${token}`;

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      console.log('Não autorizado');
    }
    return error;
  },
);

export default api;
