/* eslint-disable no-console */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import verifyUserPermission from 'utils/verifyUserPermission';
import WarehouseItemList from 'components/WarehouseItemList';
import WarehouseListCost from 'components/WarehouseListCost';
import ZeroItemsInclusiveList from 'components/ZeroItemsInclusiveList';

interface ComponentSelectorProps {
  inputString: string;
}

type ButtonsType = 'available' | 'finance' | 'all';

const buttonsObject: Record<ButtonsType, ButtonsType> = {
  available: 'available',
  finance: 'finance',
  all: 'all',
};

export const ComponentSelector: React.FC<ComponentSelectorProps> = ({
  inputString: item,
}: ComponentSelectorProps) => {
  // Verifica permissão para os botões
  const hasFinancePermission = verifyUserPermission('finance_button');
  const hasServicePermission = verifyUserPermission('all_button');
  const hasDefaultPermission = verifyUserPermission('available_button');

  // Estado para controlar qual componente está selecionado
  const [selectedComponent, setSelectedComponent] = useState<ButtonsType>(
    () => {
      const storageComponent = localStorage.getItem(
        '@warehouse_explorer:lastActiveComponent',
      );
      return storageComponent
        ? JSON.parse(storageComponent)
        : buttonsObject.available;
    },
  );

  // Estados para controlar o estado dos botões
  const [allChecked, setAllChecked] = useState<boolean>(() => {
    const storageRole = localStorage.getItem(
      '@warehouse_explorer:lastAllBtnRole',
    );
    return storageRole ? JSON.parse(storageRole) : false;
  });

  const [financeChecked, setFinanceChecked] = useState<boolean>(() => {
    const storageRole = localStorage.getItem(
      '@warehouse_explorer:lastFinanceBtnRole',
    );
    return storageRole ? JSON.parse(storageRole) : false;
  });

  const [availableChecked, setAvailableChecked] = useState<boolean>(() => {
    const storageRole = localStorage.getItem(
      '@warehouse_explorer:lastAvailableBtnRole',
    );
    return storageRole ? JSON.parse(storageRole) : buttonsObject.available;
  });

  // Efeitos para atualizar os estados no localStorage
  useEffect(() => {
    localStorage.setItem(
      '@warehouse_explorer:lastAllBtnRole',
      JSON.stringify(allChecked),
    );
  }, [allChecked]);

  useEffect(() => {
    localStorage.setItem(
      '@warehouse_explorer:lastFinanceBtnRole',
      JSON.stringify(financeChecked),
    );
  }, [financeChecked]);

  useEffect(() => {
    localStorage.setItem(
      '@warehouse_explorer:lastAvailableBtnRole',
      JSON.stringify(availableChecked),
    );
  }, [availableChecked]);

  useEffect(() => {
    localStorage.setItem(
      '@warehouse_explorer:lastActiveComponent',
      JSON.stringify(selectedComponent),
    );
  }, [selectedComponent]);

  // Funções para lidar com cliques nos botões
  const handleButtonClick = (component: ButtonsType): void => {
    if (component in buttonsObject) {
      setSelectedComponent(component);
      setFinanceChecked(component === 'finance');
      setAvailableChecked(component === 'available');
      setAllChecked(component === 'all');
    } else {
      console.error(`Component "${component}" is not valid.`);
    }
  };

  return (
    <div>
      <div>
        {/* Botão "Disponíveis"  (exibido apenas se houver permissão) */}
        {hasDefaultPermission && (
          <FormControlLabel
            control={
              <Switch
                checked={availableChecked}
                onChange={() => handleButtonClick(buttonsObject.available)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Disponíveis"
          />
        )}
        {/* Botão "Todos" (exibido apenas se houver permissão) */}
        {hasServicePermission && (
          <FormControlLabel
            control={
              <Switch
                checked={allChecked}
                onChange={() => handleButtonClick(buttonsObject.all)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Todos"
          />
        )}
        {/* Botão "Financeiro" (exibido apenas se houver permissão) */}
        {hasFinancePermission && (
          <FormControlLabel
            control={
              <Switch
                checked={financeChecked}
                onChange={() => handleButtonClick(buttonsObject.finance)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Financeiro"
          />
        )}
      </div>
      {/* Renderiza o componente selecionado */}
      <div>
        {selectedComponent === buttonsObject.available && (
          <WarehouseItemList query={item} />
        )}
        {selectedComponent === buttonsObject.finance && (
          <WarehouseListCost query={item} />
        )}
        {selectedComponent === buttonsObject.all && (
          <ZeroItemsInclusiveList query={item} />
        )}
      </div>
    </div>
  );
};
